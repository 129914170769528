import React, { useState } from "react";
import { navigate } from "gatsby";
import { useFormik } from "formik";

import firebase from "./../utilities/firebase.js";
import Header from "../components/header";

const ResetPassword = () => {
  const [loginError, setLoginError] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const validate = (values) => {
    console.log(values);
    console.log("first name: ", values.firstName);
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    console.log(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      setLoginError("");

      let auth = firebase.auth();

      auth
        .sendPasswordResetEmail(values.email)
        .then(function () {
          setEmailSent(true);
        })
        .catch(function (error) {
          //var errorCode = error.code;
          //var errorMessage = error.message;
          console.log(error.code, error.message);
          console.log(error);
          console.log(error.code === "auth/wrong-password");
          if (
            error.code === "auth/wrong-password" ||
            error.code === "auth/user-not-found"
          ) {
            console.log("inne");
            setLoginError("Wrong username or password");
          }
        });
    },
  });

  console.log(loginError);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="h-full flex flex-col justify-center lg:py-0 lg:pt-20">
        <div>


          <div className="cardContainerOuter max-w-xl mx-auto">
            <div className="cardContainerInner bg-gray-50">
              <div className="text-center mb-16">
                <h2>Reset password</h2>
              </div>
              {emailSent ? (
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                  <form className="space-y-6" onSubmit={formik.handleSubmit}>
                    <div>
                      <p>
                        We sent you an email with a link to reset your password.
                      </p><br />
                      <p>
                        Please follow the link from your email.
                      </p>
                    </div>
                    {/*<div>
                  <button
                    onClick={() => navigate("/sign-in")}
                    type="submit"
                    className="w-full primary-button"
                  >
                    Go back to login
                  </button>
          </div>*/}
                  </form>

                </div>
              ) : (
                <form className="space-y-6" onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                      <label htmlFor="email">Email address</label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className={`${formik.errors.email && formik.touched.email
                            ? "border-red-800"
                            : "border-gray-300"
                            } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                            {formik.errors.email}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="primary-button">
                      Reset password
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
